<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading"></loading>
    <form
      @submit.prevent="handleSubmit(submitStepTwo)"
      enctype="multipart/form-data"
      class="px-6 pt-6"
    >
      <div class="w-full pb-4"></div>

      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <text-input
            rules="required"
            label="Vehicle Name"
            placeholder="Write"
            v-model="form.name"
            v-on:input="changeValues"
          />
        </div>

        <div class="w-1/2 pl-2">
          <ValidationProvider
            name="Vehicle Type"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Vehicle Type *" />
            <t-rich-select
              :options="bike_categories"
              valueAttribute="value"
              textAttribute="name"
              placeholder="Select"
              :variant="{ danger: !!errors[0] }"
              v-model="form.bike_category"
              @change="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <ValidationProvider
            name="Vehicle Manufacturer"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Vehicle Manufacturer *" />
            <t-rich-select
              :options="manufacturers"
              valueAttribute="id"
              textAttribute="name"
              placeholder="Select"
              :variant="{ danger: !!errors[0] }"
              v-model="form.manufacturer"
              @change="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="w-1/2 pl-2">
          <text-input
            rules="required"
            label="Vehicle QR Code"
            placeholder="e.g. 1210010001"
            v-model="form.qr_code"
            v-on:input="changeValues"
          />
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-1/2 pr-2">
          <ValidationProvider
            name="Assign Fleet"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Assign Fleet *" />
            <t-rich-select
              :options="fleets"
              valueAttribute="id"
              textAttribute="name"
              placeholder="Select"
              :variant="{ danger: !!errors[0] }"
              v-model="form.fleet"
              @change="changeValues"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="w-1/2 pl-2">
          <!-- todo: update service area -->
          <text-input
            rules=""
            label="Service Area *"
            placeholder="--"
            v-model="form.service_area"
            disabled="disabled"
            v-on:input="changeValues"
          />
        </div>
      </div>

      <div>
        <input-label text="Description " />
        <t-textarea
          rules="required"
          v-model="form.description"
          v-on:input="changeValues"
        ></t-textarea>
      </div>
      <input-label text="Vehicle Condition " class="pt-4" />
      <div class="flex items-center ">
        <div class="w-1/2">
          <div class="flex condition-box">
            <div class="items-center block m-auto ml-12">
              <div class="block">
                <span class="block condition-box-title">Available</span>
                <span class="block mt-2 condition-box-sub-title"
                  >Operational
                </span>
              </div>
            </div>
            <div class="flex items-center m-auto">
              <t-toggle :checked="form.is_operational" />
            </div>
          </div>
        </div>

        <div class="w-1/2">
          <div class="flex iot-box">
            <div class="items-center block m-auto ml-12">
              <div class="block">
                <span class="block condition-box-title">IOT Fault</span>
                <span class="block mt-2 condition-box-sub-title"
                  >Service Status
                </span>
              </div>
            </div>
            <div class="flex items-center m-auto">
              <t-toggle :checked="form.service_status" />
            </div>
          </div>
        </div>
      </div>
      <!-- end: adress -->
      <button type="submit" ref="stepTwoSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'

export default {
  name: 'addSingleScooterStep2',
  components: {
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      fleets: [],
      manufacturers: [],
      bike_categories: [
        {
          id: 0,
          name: 'Bike',
          value: 'S',
        },
        {
          id: 1,
          name: 'Scooter',
          value: 'P',
        },
        {
          id: 2,
          name: 'EBike',
          value: 'E',
        },
      ],
    }
  },
  async created() {
    this.form.service_area = '--'

    this.manufacturers = await this.$http
      .get(SingleScooterConfig.api.vehicle_manufacture)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))
    this.fleets = await this.$http
      .get(SingleScooterConfig.api.fleets)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))
    if (this.isEditing) {
      console.log('step2 = ', this.step2)
      this.form = this.step2
    }
  },
  mounted() {
    console.warn(this.step1.id)
    //console.log("c4", this.step4.owner_user.id);
    // if (this.isEditing) {
    //     this.form = { ...this.step2 };
    // }
  },
  computed: {
    ...mapGetters('scooter', ['isEditing', 'currentEditingScooterId']),
    ...mapState({
      // retrieve organization detail from step 1
      step1: (state) => state.scooter.step1,
      step2: (state) => state.scooter.step2,
      // retrieve organization owner detail from step 4
    }),
  },
  methods: {
    reloadData() {
      this.form = this.step2
    },
    changeValues() {
      console.log('change')
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal' && this.isEditing) {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepTwoSubmitButton.click()
      }
    },
    submitStepTwo: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      // console.log("step = ", this.step2.id);
      let url = this.isEditing
        ? SingleScooterConfig.api.update_vehicle(this.step2.id)
        : SingleScooterConfig.api.create_vehicle //made by dongdong
      console.log('url = ', url, method)
      if (
        this.form.service_area == 0 ||
        this.form.is_operational == undefined ||
        this.form.is_operational == null
      ) {
        this.form.is_operational = false
      } else {
        this.form.is_operational = true
      }

      this.form.lock = this.step1.id
      // this.form.qr_code = this.step1.qr_code;

      delete this.form.service_area
      console.log('is_ = ', this.form)

      let data = new xMan(this.form).toFormData()

      let message = 'Scooter added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // todo: remove logs
        console.log({ res: response.data })
        // Reset the form
        this.form = {}
        // Notify
        this.$notify(
          {
            group: 'important',
            type: 'success',
            title: 'Success',
            text: message,
          },
          5000
        )
        // Resolved
        console.log('response = ', response.data)
        EventBus.$emit(SingleScooterConfig.events.succeededStep2, response.data)
        // dispatchEvent(new Event(SingleScooterConfig.events.sorToggle));
        return true
      } catch (e) {
        this.isLoading = false
        console.log({ e1: e.response })
        // var errorData = Object.values(e.response.data);
        // if (errorData.length > 0) {
        //     this.$emit('show_error' , errorData[0][0])
        // }
      }
    },
  },
}
</script>
<style scoped>
.condition-box {
  width: 255px;
  height: 130px;
  border: 1px solid #1bca09;
  background: #f2fff1;
  margin-top: 20px;
  border-radius: 5px;
}
.condition-box-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.condition-box-sub-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
.iot-box {
  width: 255px;
  height: 130px;
  border: 1px solid #d3d3d3;
  background: #ffffff;
  margin-top: 20px;
  border-radius: 5px;
  float: right;
}
.iot-box-title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.iot-box-sub-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
