<template>
  <slide-over-right
    :id="sorId"
    v-on:showModal="showModal"
    editType="single-scooter"
    class="vld-parent"
  >
    <loading :active.sync="isLoading" :is-full-page="false"></loading>

    <template v-slot:header>
      <div class="pb-3 panel-title">{{ titleText }}</div>
      <stepper
        :steps="stepStatusArr"
        v-model="currentStep"
        :edit="is_edit"
        ref="stepper"
        :with-divider="false"
        v-on:input="changeTabs"
        v-on:errors="checkErrors"
      >
        <template v-slot:step-1> IOT Info </template>
        <template v-slot:step-2> Vehicle Info </template>
      </stepper>
    </template>

    <template v-if="currentStep == 1">
      <add-single-scooter-step-1
        ref="step1"
        v-on:isValid="isValidFunc"
      ></add-single-scooter-step-1>
    </template>
    <template v-if="currentStep == 2">
      <add-single-scooter-step-2
        ref="step2"
        v-on:isValid="isValidFunc"
      ></add-single-scooter-step-2>
    </template>

    <template v-slot:footer>
      <div class="flex">
        <div class="w-2/5">
          <slide-over-left-trigger :id="sorId" v-on:cancelEdit="showModal">
            <anchor-button
              variant="secondary"
              class="text-center custom-font cancel-btn"
            >
              <span class="font-semibold custom-font">
                Cancel
              </span>
            </anchor-button>
          </slide-over-left-trigger>
        </div>
        <div class="flex flex-wrap justify-end w-3/5">
          <t-button type="submit" class="mx-3" @click="submitStep(currentStep)">
            {{ !isEditing ? 'Save & Continue' : 'Update' }}
          </t-button>
        </div>
      </div>
    </template>
  </slide-over-right>
</template>

<script>
// import { mapState } from "vuex";
import { mapGetters } from 'vuex'

import { EventBus } from '@/utils/EventBus'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleConfig } from '@/config/VehicleConfig'
import SlideOverRight from '@/components/modals/SlideOverRight'

import SlideOverLeftTrigger from '@/components/modals/SlideOverLeftTrigger'
import AnchorButton from '@/components/form/AnchorButton.vue'
import Stepper from '@/components/stepper/Stepper'

import AddSingleScooterStep1 from './AddSingleScooterStep1.vue'
import AddSingleScooterStep2 from './AddSingleScooterStep2.vue'

export default {
  name: 'AddSingleScooter',
  components: {
    SlideOverRight,
    SlideOverLeftTrigger,
    AnchorButton,
    Stepper,

    AddSingleScooterStep1,
    AddSingleScooterStep2,
  },
  props: {
    is_edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sorId: SingleScooterConfig.events.sorId,
      isLoading: false,
      stepStatusArr: [],
    }
  },
  computed: {
    ...mapGetters('scooter', ['isEditing', 'currenteditingScooterId']),
    currentStep: {
      get() {
        return this.$store.getters['scooter/currentStep']
      },
      set(updatedStep) {
        this.$store.dispatch('scooter/saveStep', {
          step: updatedStep,
        })
      },
    },
    is_editing() {
      return this.isEditing
    },
    titleText() {
      return this.isEditing ? 'Edit Scooter' : 'Add Scooter'
    },
  },
  watch: {
    // organizationId: function(updatedId) {
    // }
  },
  async mounted() {
    EventBus.$on(SingleScooterConfig.events.editingData, (item) => {
      this.$store.dispatch('scooter/saveStep', 1)
      this.$store.dispatch('scooter/forgetEditingStep')
      this.$store.dispatch('scooter/forgetAllStepsData')
      this.$store.dispatch('scooter/forgetEditingScooterId')

      console.log('item = ', item)
      if (Object.keys(item).length > 1) {
        this.populateScooterStore(item.id)
      } else {
        var result = []
        for (var i = 0; i < 2; i++) {
          var obj = {
            visited: false,
            disabled: true,
            index: i,
            value: i + 1,
          }
          result.push(obj)
        }
        this.stepStatusArr = result
        var vm = this
        setTimeout(() => {
          if (vm.$refs.stepper != undefined) {
            vm.$refs.stepper.changeStepsArr(result)
          } else {
            console.log('undefined')
          }
        }, 500)
      }
    })
    EventBus.$on(SingleScooterConfig.events.succeededStep1, (item) => {
      console.log('success = ', item)
      var data = {
        lock: item,
      }
      var step1_data = this.getStepData(data, 1)
      this.$store.dispatch('scooter/saveStepData', {
        step: 1,
        data: step1_data,
      })
      this.$store.dispatch('scooter/nextStep', {
        step: 2,
      })
      this.$refs.stepper.nextWithBtn()
    })
    EventBus.$on(SingleScooterConfig.events.succeededStep2, (item) => {
      this.$store.dispatch('scooter/saveStepData', {
        step: 2,
        data: item,
      })
      // alert("step2 Completed");
      this.$store.dispatch('scooter/forgetStep')
      this.$store.dispatch('scooter/forgetAllStepsData')
      dispatchEvent(new Event(VehicleConfig.events.refresh))
      dispatchEvent(new Event(SingleScooterConfig.events.sorClose))
    })
  },
  methods: {
    getSteps(steps, index) {
      this.stepStatusArr = steps
      console.log('now = ', index)
    },
    showModal() {
      console.log('exit')
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
    submitStep(step) {
      this.$refs[`step${step}`].submit('normal')
    },
    //made by guang
    isValidFunc(flag, index, isNext, isPrevious) {
      //only move
      this.isValid = flag
      if (this.isValid || isPrevious) {
        this.$refs.stepper.enoughMove(index)
        this.isValid = false
      }
    },
    checkErrors(oldIdx, index, isNext, isPrevious) {
      if (isPrevious && !isNext) {
        this.$refs.stepper.enoughMove(index)
      } else {
        this.$refs[`step${oldIdx + 1}`].submit(
          'tabselect',
          index,
          isNext,
          isPrevious
        )
      }
    },
    changeTabs(index) {
      var vm = this
      setTimeout(() => {
        switch (index) {
          case 1:
            if (vm.$refs.step1) vm.$refs.step1.reloadData()
            break
          case 2:
            if (vm.$refs.step2) vm.$refs.step2.reloadData()
            break
        }
      }, 1000)
    },
    getStepData(data, step) {
      var index = step - 1
      var result = {}
      switch (step) {
        case 1:
          result = {
            id: data.lock.id,
            lock_id: data.lock.lock_id,
            name: data.lock.name ? data.lock.name : '',
            power_level:
              data.lock.power_level && data.lock.power_level != null
                ? data.lock.power_level
                : '',
            is_locked:
              data.lock.is_locked && data.lock.is_locked != null
                ? data.lock.is_locked
                : '',
            qr_code:
              data.lock.qr_code && data.lock.qr_code != null
                ? data.lock.qr_code
                : '',
            is_operational:
              data.lock.is_operational && data.lock.is_operational != null
                ? data.lock.is_operational
                : '',
            // Added iccid - farhan
            iccid: data.lock && data.lock.iccid ? data.lock.iccid : '',

            sim_number:
              data.lock.sim_number && data.lock.sim_number != null
                ? data.lock.sim_number
                : '',
            firmware_key:
              data.lock.firmware_key && data.lock.firmware_key != null
                ? data.lock.firmware_key
                : '',
            firmware_type:
              data.lock.firmware_type && data.lock.firmware_type != null
                ? data.lock.firmware_type
                : '',
            factory_qr:
              data.lock.factory_qr && data.lock.factory_qr != null
                ? data.lock.factory_qr
                : '',
            start_date:
              data.lock.start_date && data.lock.start_date != null
                ? data.lock.start_date
                : '',
            operator_apn:
              (data.lock.operator_apn && data.lock.operator_apn) != null
                ? data.lock.operator_apn
                : '',
            description:
              data.lock.description && data.lock.description != null
                ? data.lock.description
                : '',
            manufacturer:
              data.manufacturer && data.lock.manufacturer.id
                ? data.lock.manufacturer.id
                : '',
          }
          if (data.lock && data.lock != undefined) {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        case 2:
          var category = data.bike_category
          // if (data.bike_category == "S") {
          //   category = "P";
          // } else if (data.bike_category == "SCOOTER") {
          //   category = "S";
          // } else {
          //   category = "B";
          // }
          console.warn('sepopu', data)
          result = {
            id: data.id,
            bike_category: category,
            fleet: data.fleet.id,
            name: data.name,
            qr_code: data.qr_code,
            manufacturer: data.manufacturer ? data.manufacturer.id : '',
            description: data.description,
            vehicle_conditions: data.vehicle_conditions
              ? data.vehicle_conditions
              : false,
            heart_beat: data.heart_beat,
          }
          if (data.bike_category && data.bike_category != undefined) {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: true,
              disabled: false,
            }
          } else {
            this.stepStatusArr[index] = {
              index: index,
              value: index + 1,
              visited: false,
              disabled: true,
            }
          }
          break
        default:
          break
      }
      return result
    },

    async populateScooterStore(id) {
      this.isLoading = true

      this.$http
        .get(SingleScooterConfig.api.single_vehicle(id))
        .then((res) => {
          var step1_init_data = this.getStepData(res.data, 1)
          var step2_init_data = this.getStepData(res.data, 2)

          console.warn('step12', this.stepStatusArr)
          console.warn('stepp', this.$refs.stepper)
          if (this.$refs.stepper) {
            this.$refs.stepper.changeStepsArr(this.stepStatusArr)
          }
          localStorage.setItem('editId', step1_init_data.id)
          localStorage.setItem('step1', JSON.stringify(step1_init_data))
          localStorage.setItem('step2', JSON.stringify(step2_init_data))

          this.$store.dispatch('scooter/saveEditingScooterId', {
            data: id,
          })

          this.$store.dispatch('scooter/saveEditingScooterId', {
            data: id,
          })

          this.$store.dispatch('scooter/saveEditingStep', {
            step: 1,
          })

          this.$store.dispatch('scooter/saveStepData', {
            step: 1,
            data: step1_init_data,
          })
          this.$store.dispatch('scooter/saveStepData', {
            step: 2,
            data: step2_init_data,
          })
        })
        .catch((error) => {
          console.log('popu-err', error)
        })

      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
.underline {
  color: #000000;
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 10px;
}
.normal {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6c6e71;
}
.cancel-btn {
  width: 150px;
  margin-left: 0px;
}
</style>
