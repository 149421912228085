<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading"></loading>
    <div class="px-6 pt-6 pb-6">
      <input-label text="Choose iOT" />
      <t-rich-select
        :options="iots"
        valueAttribute="id"
        textAttribute="name"
        placeholder="--"
        v-model="sel_iot"
        @change="changeIOT"
      />
    </div>

    <form
      @submit.prevent="handleSubmit(submitStepOne)"
      enctype="multipart/form-data"
      class="px-6 pt-6"
    >
      <div class="grid grid-cols-2 gap-4">
        <text-input
          rules="required"
          label="Name"
          placeholder="Name"
          v-model="form.name"
          v-on:input="changeValues"
          :disabled="sel_iot != '' ? true : false"
        />

        <div>
          <input-label text="IoT Type" />
          <t-rich-select
            v-model="form.iot_category"
            :options="getIotTypeOptions"
            valueAttribute="value"
            textAttribute="text"
            placeholder="--"
          />
        </div>
      </div>

      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <ValidationProvider
            name="Manufacturer"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Manufacturer *" />
            <t-rich-select
              :options="manufacturers"
              valueAttribute="id"
              textAttribute="name"
              placeholder="Select"
              :variant="{
                danger: !!errors[0],
                readonly: sel_iot != '' ? true : false,
              }"
              v-model="form.manufacturer"
              @change="changeValues"
              :disabled="sel_iot != '' ? true : false"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            rules="required|min:15"
            label="Device IMEI"
            placeholder="e.g 867584039515528"
            v-model="form.lock_id"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
      </div>
      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            rules=""
            label="SIM ICCID"
            placeholder="e.g 8965011904070266453"
            v-model="form.iccid"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            rules=""
            label="SIM Phone Number"
            placeholder="e.g. 01757262760"
            v-model="form.sim_number"
            input_type="phone"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
      </div>

      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            rules=""
            label="Factory QR"
            placeholder="e.g 100010001"
            v-model="form.factory_qr"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            rules=""
            label="Firmware type"
            placeholder="e.g ext2"
            v-model="form.firmware_type"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
      </div>

      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            rules=""
            label="Firmware key"
            placeholder="e.g yOTmK50z"
            v-model="form.firmware_key"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
        <div class="pl-2 w-1/2">
          <ValidationProvider
            name="Lock Condition"
            rules=""
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Lock condition" />
            <t-rich-select
              :options="conditions"
              valueAttribute="value"
              textAttribute="name"
              placeholder="Select"
              v-model="form.is_operational"
              @change="changeValues"
              :disabled="sel_iot != '' ? true : false"
              :class="sel_iot != '' ? 'disabled_class' : 'enabled_class'"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <!-- end: city & zip -->

      <!-- start: Domain & Port -->
      <div class="flex items-center">
        <div class="pr-2 w-1/2">
          <text-input
            placeholder="write"
            rules=""
            type="date"
            label="Registration Date"
            v-model="form.start_date"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
        <div class="pl-2 w-1/2">
          <text-input
            placeholder="write"
            rules=""
            label="Operator APN"
            v-model="form.operator_apn"
            v-on:input="changeValues"
            :disabled="sel_iot != '' ? true : false"
          />
        </div>
      </div>
      <!-- end: Domain & Port -->

      <!-- start: address -->
      <div>
        <input-label text="Description " />
        <t-textarea
          rules="required"
          v-model="form.description"
          v-on:input="changeValues"
          :disabled="sel_iot != '' ? true : false"
        ></t-textarea>
      </div>
      <!-- end: adress -->
      <button type="submit" ref="stepOneSubmitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import xMan from '@/utils/xMan'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'
import { getIotTypeOptions } from '@/utils'

export default {
  name: 'addSingleScooterStep1',
  components: {
    InputLabel,
    TextInput,
  },
  data: function() {
    return {
      isLoading: false,
      form: {},
      fleets: [],
      manufacturers: [],
      iots: [],
      conditions: [
        {
          value: 'false',
          name: 'Not Operational',
        },
        {
          value: 'true',
          name: 'Operational',
        },
      ],
      sel_iot: '',
      lock_id: '',
    }
  },
  async created() {
    this.sel_iot = ''

    this.manufacturers = await this.$http
      .get(SingleScooterConfig.api.iot_manufacture)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))

    var array = await this.$http
      .get(SingleScooterConfig.api.iot.unassigned)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))

    var result = [
      {
        id: '',
        name: '--',
      },
    ]
    if (array.length > 0) {
      for (var i = 0; i < array.length; i++) {
        result.push(array[i])
      }
      this.iots = result
    } else {
      // todo: add -- if no iot
      this.iots = []
    }

    if (this.isEditing) {
      if (this.step1.id) {
        console.log('----------s1--------', this.step1)
        // append the current lock to the fetched unassigned lock array
        this.iots.unshift(this.step1)

        this.sel_iot = this.step1.id
        this.lock_id = this.step1.id

        // if (this.sel_iot == this.lock_id) {
        //   this.sel_iot = "";
        // }

        if (this.step1.is_operational == false)
          this.step1.is_operational = 'false'
        else this.step1.is_operational = 'true'
      }

      this.form = this.step1
    }
  },
  mounted() {
    //console.log("c4", this.step4.owner_user.id);
    // if (this.isEditing) {
    //   this.form = { ...this.step4.form };
    // console.warn("asd4", this.step4);
    // }
    // console.log(this.sel_iot, this.scooter)
  },
  computed: {
    getIotTypeOptions,
    ...mapGetters('scooter', ['isEditing', 'currenteditingScooterId']),
    ...mapState({
      step1: (state) => state.scooter.step1,
    }),
    getPasswordValidationRules() {
      return this.isEditing ? 'min:8' : 'required|min:8'
    },
  },
  methods: {
    reloadData() {
      if (this.step1.id) {
        this.sel_iot = this.step1.id
        if (this.sel_iot == localStorage.getItem('editId')) {
          this.sel_iot = ''
        }
      }
      if (this.step1.is_operational == false)
        this.step1.is_operational = 'false'
      else this.step1.is_operational = 'true'
      // console.warn("relos", this.step1);
      this.form = this.step1
    },
    changeValues() {
      console.log('change')
    },
    submit: async function(btn_type, index, isNext, isPrevious) {
      if (btn_type != 'normal') {
        const isValid = await this.$refs.observer.validate()
        this.$emit('isValid', isValid, index, isNext, isPrevious)
      } else {
        this.$refs.stepOneSubmitButton.click()
      }
    },
    async changeIOT() {
      if (this.sel_iot == '') {
        if (this.isEditing) {
          this.form = {
            lock_id: this.step1.lock_id,
            name: this.step1.name,
            iot_category: this.step1.iot_category,

            manufacturer: this.step1.manufacturer
              ? this.step1.manufacturer
              : '',
            iccid: this.step1.iccid,
            is_locked: this.step1.is_locked,
            qr_code: this.step1.qr_code ? this.step1.qr_code : '',
            is_operational: this.step1.is_operational == 'true' ? true : false,
            sim_number: this.step1.sim_number ? this.step1.sim_number : '',
            firmware_key: this.step1.firmware_key,
            firmware_type: this.step1.firmware_type
              ? this.step1.firmware_type
              : '',
            factory_qr: this.step1.factory_qr ? this.step1.factory_qr : '',
            start_date: this.step1.start_date
              ? new Date(this.step1.start_date)
              : '',
            operator_apn: this.step1.operator_apn
              ? this.step1.operator_apn
              : '',
            description: this.step1.description ? this.step1.description : '',
          }
        } else {
          this.form = {}
        }
      } else {
        await this.$http
          .get(SingleScooterConfig.api.single_lock(this.sel_iot))
          .then((res) => {
            var data = res.data
            this.form = {
              lock_id: data.lock_id,
              name: data.name,
              iot_category: this.step1.iot_category,

              manufacturer: data.manufacturer ? data.manufacturer : '',
              is_locked: data.is_locked,
              qr_code: data.qr_code ? data.qr_code : '',
              is_operational: data.is_operational
                ? data.is_operational
                : 'false',
              iccid: data.iccid || '',
              sim_number: data.sim_number ? data.sim_number : '',
              firmware_key: data.firmware_key,
              firmware_type: data.firmware_type ? data.firmware_type : '',
              factory_qr: data.factory_qr ? data.factory_qr : '',
              start_date: data.start_date ? new Date(data.start_date) : '',
              operator_apn: data.operator_apn ? data.operator_apn : '',
              description: data.description ? data.description : '',
            }
          })
          .catch((err) => console.log('err = ', err))
      }
    },
    submitStepOne: async function() {
      this.isLoading = true

      if (this.form.lock_id) {
        this.form.qr_code = this.form.lock_id
      }

      if (
        this.sel_iot != '' &&
        this.sel_iot != localStorage.getItem('editId')
      ) {
        this.form.id = this.sel_iot
        this.isLoading = false
        EventBus.$emit(SingleScooterConfig.events.succeededStep1, this.form)
        return true
      } else {
        let method = this.isEditing ? 'PATCH' : 'POST'
        var id = ''
        if (this.sel_iot == '' && this.isEditing) {
          id = localStorage.getItem('editId')
        }
        let url = this.isEditing
          ? SingleScooterConfig.api.update_iot(id)
          : SingleScooterConfig.api.create_iot //made by dongdong

        if (this.form.is_operational == 'false') {
          this.form.is_operational = false
        } else {
          this.form.is_operational = true
        }

        delete this.form.start_date

        let data = new xMan(this.form).toFormData()

        let message = this.isEditing
          ? 'IOT updated successfully'
          : 'IOT added successfully'
        try {
          let response = await this.$http({ method, url, data })
          this.isLoading = false
          // todo: remove logs
          console.log({ res: response.data })
          // Reset the form
          this.form = {}
          // Notify
          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
          // Resolved
          console.log('response = ', response.data)
          EventBus.$emit(
            SingleScooterConfig.events.succeededStep1,
            response.data
          )
          return true
        } catch (e) {
          this.isLoading = false
          console.log({ e1: e.response.data })
          // var errorData = Object.values(e.response.data);
          // if (errorData.length > 0) {
          //     this.$emit('show_error' , errorData[0][0])
          // }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.enabled_class button {
  background: white !important;
}
.disabled_class div button {
  background: #f2f2f2 !important;
}
</style>
